/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import * as React from 'react';
import PropTypes from 'prop-types';
import { Card, CardActions, CardContent, CardMedia, Typography, Paper, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'gatsby';
import ImageDisplay from '../image-display';

const ListCardContainer = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  height: '100%',
  padding: 0,
  textAlign: 'left',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
  backgroundColor: 'transparent',
  '& .cardContainer': {
    boxShadow: 'none'
  },
  '& .customAnchorLink': {
    textDecoration: 'none'
  },
  '& .listCardActions': {
    position: 'relative',
    padding: '8px 0 0'
  },
  '& .listBodyStyle': {
    color: '#383838',
    fontSize: '0.7rem',
    marginBottom: '5px'
  },
  '& .listDivStyle': {
    position: 'absolute !important',
    right: '0 !important'
  },
  '& .listButtonStyle': {
    padding: '0 !important',
    minWidth: '0px !important',
    color: '#383838'
  },
  '& .listIconStyle': {
    fontSize: '16px !important'
  },
  '& .cardContent': {
    paddingBottom: '16px !important'
  },
  '& .listCardContent1': {
    fontWeight: 'bold',
    marginBottom: 0
  },
  '& .listCardContent2': {
    fontSize: '12px !important',
    color: '#898989 !important'
  },
  '& .assetCardMedia': {
    objectFit: 'initial !important'
  },
  '& .cardMain': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }
}));
function ListCard({ details, link }) {
  if (link.includes('program')) {
    details.image = details?.programThumbnail;
  } else if (link.includes('event')) {
    details.image = details?.eventThumbnail;
  } else if (link.includes('knowledge-center')) {
    details.image = details?.knowledgeThumbnail;
  }
  return (
    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
      <ListCardContainer>
        <Link
          to={link}
          className='customAnchorLink'
          aria-describedby={details.title}
        >
          <Card className='cardMain'>
            <CardContent className='cardContent'>
              <Typography variant='cardcategory' className='listBodyStyle'>{details.category}</Typography>
              <Typography gutterBottom variant='h5' component='div' className='listCardContent1'>
                {details.programShortTitle ? details.programShortTitle : details.title}
              </Typography>
              {/* Actions */}
              <CardActions className='listCardActions' sx={{ padding: 0 }}>
                <Typography variant='carddate' color='text.secondary' className='listCardContent2'>
                  {details.updatedAt}
                </Typography>
                <Typography component='div' className='listDivStyle'>
                  {/* <Typography variant='button' size='small' sx={{ mr: '5px' }} className='listButtonStyle'><FavoriteBorderOutlinedIcon className='listIconStyle' /></Typography>
                  <Typography variant='button' size='small' className='listButtonStyle'><ShareIcon className='listIconStyle' /></Typography> */}
                </Typography>
              </CardActions>
            </CardContent>
            {/* Card Media */}
            {
              details?.programThumbnail && (
                <ImageDisplay data={details.programThumbnail} />
              )
            }
            {
              details?.eventThumbnail && (
                <ImageDisplay data={details.eventThumbnail} />
              )
            }
            {
              details?.knowledgeThumbnail && (
                <ImageDisplay data={details.knowledgeThumbnail} />
              )
            }
          </Card>
        </Link>
      </ListCardContainer>
    </Grid>
  );
}

ListCard.propTypes = {
  details: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired
};

export default ListCard;
