/* eslint-disable no-unsafe-optional-chaining */
import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Container from '@mui/material/Container';
import Pagination from '@mui/material/Pagination';
import ToggleButton from '@mui/material/ToggleButton';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import FilterListIcon from '@mui/icons-material/FilterList';
import Popover from '@mui/material/Popover';
import Chip from '@mui/material/Chip';

import ListCard from '../../components/list-card';
import { listingUrlMap } from '../../utils/constants';
import PageListPagination from '../../components/list-pagination';
import Layout from '../../components/layout/layout';
import ListFilter from '../../components/list-filter';
import DynamicComponent from '../../utils/dynamic-component';

import { getHomePageFilters, filterHomePageItems } from '../../utils/filter-util';

const CardBox = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,

  '& .heading': {
    fontWeight: 'bold',
    marginBottom: 0
  },
  '& .divider': {
    margin: '0 auto'
  },
  '& .MuiCardContent-root': {
    height: '75%'
  },
  '& .exploreBtn': {
    backgroundColor: 'transparent',
    border: 'none',
    color: '#E95525',
    textDecoration: 'underline',
    '&:hover': {
      border: 'none'
    }
  },
  '& .cardRef': {
    backgroundColor: '#fafafa',
    height: '100%',
    '&:hover': {
      transform: 'scale(1.05)',
      backgroundColor: '#fff',
      cursor: 'pointer'
    },
    [theme.breakpoints.down('md')]: {
      padding: '0px',
      minWidth: 'auto'
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '274px'
    }

  },
  '& .anchorLink': {
    textDecoration: 'none',
    color: '#000000'
  },
  '& .listCards': {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      overflow: 'scroll',
      alignItems: 'stretch',
      flexWrap: 'inherit'
    }
  }
}));

const ListFilters = styled(Box)(() => ({
  '& .displayBlock': {
    // display: 'block',
    position: 'absolute',
    backgroundColor: '#fff',
    left: '5px',
    width: '100%',
    top: '72px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  '& .displayNone': {
    display: 'none'
  },
  '& .filterPopover': {
    alignItems: 'CENTER',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px'
  }
}));
const ToggleButtonCmp = styled(ToggleButton)(() => ({
  // fontFamily: '"Avenir Bold",  "Helvetica", "Arial", sans-serif',
  fontFamily: '"Source Sans Pro", sans-serif',
  textTransform: 'capitalize',
  fontWeight: 'bold',
  '&.Mui-selected': {
    background: '#E95525 !important',
    color: '#000000'
  },
  '&.Mui-selected::after': {
    content: '""',
    height: 0,
    width: 0,
    display: 'block',
    borderWidth: '10px 10px 10px 0px',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    position: 'absolute',
    top: '33.5px',
    borderColor: 'transparent transparent #E95525 transparent',
    borderStyle: 'solid',
    transform: 'rotate(0deg)',
    left: '35%'
  }
}));

function LeadDirectorLandingPage({ data, location }) {
  const leadPageData = data?.leadDirectorDetails;
  const allListingDetailsData = data?.listingDetails;

  allListingDetailsData?.nodes.forEach(e => {
    if (e.type === 'Program') {
      e.key = 1;
    } else if (e.type === 'Event') {
      e.key = 2;
    } else { e.key = 3; }
  });

  allListingDetailsData?.nodes.sort((a, b) => a.key - b.key);

  const mergedItems = [
    ...data?.programs.nodes.map(i => Object.assign(i, { link: 'program' })),
    ...data?.events.nodes.map(i => Object.assign(i, { link: 'event' })),
    ...data?.knowledge.nodes.map(i => Object.assign(i, { link: 'knowledge-center' }))
  ].sort((a, b) => new Date(b.updatedAt_) - new Date(a.updatedAt_));

  /** Essential for Responsive */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const matches = useMediaQuery('(min-width:767px)');
  /** Essential for Responsive Ends */

  const [pagePaginationText, setPagePaginationText] = React.useState('');
  const [allList, setAllList] = React.useState(mergedItems);

  const [rowsPerPage, setRowsPerPage] = React.useState(6);
  const [currentpageno, setCurrentpageno] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(Math.ceil(mergedItems.length / rowsPerPage));

  /** Filter Functionality Starts ------------------ */

  const [selectedFilters, setSelectedFilters] = React.useState([]);

  const [allButtonSelected, setAllButtonSelected] = React.useState(true);

  const onAllButtonChange = () => {
    // if selected, unselected -> show all, reset page, reset all filters
    const updatedSelectedFilters = selectedFilters.map(filter => ({
      label: filter.label,
      options: []
    }));

    setSelectedFilters(updatedSelectedFilters);

    // Set new Program List
    setAllList(filterHomePageItems(mergedItems, updatedSelectedFilters).slice(0, rowsPerPage));

    // Reset Page No.
    setCurrentpageno(1);
  };

  const onFilterChange = (label, selectedItems) => {
    const updatedSelectedFilters = [...selectedFilters];
    const updatedFilterIndex = selectedFilters.findIndex(filter => filter.label === label);
    if (updatedFilterIndex === -1) {
      updatedSelectedFilters.push({
        label,
        options: selectedItems
      });
    } else {
      updatedSelectedFilters[updatedFilterIndex].options = selectedItems;
    }

    setSelectedFilters(updatedSelectedFilters);

    // Reset Page No.
    setCurrentpageno(1);

    setAllButtonSelected(false);
  };

  const removeFilter = (label, optionToBeRemoved) => {
    const updatedSelectedFilters = [...selectedFilters];
    const filterToBeUpdated = updatedSelectedFilters.find(filter => filter.label === label);
    if (filterToBeUpdated) {
      filterToBeUpdated.options = filterToBeUpdated.options.filter(option => option !== optionToBeRemoved);
    }
    setSelectedFilters(updatedSelectedFilters);

    // Reset Page No.
    setCurrentpageno(1);
  };

  const checkForSelectedOptions = () => {
    const selectedOptions = selectedFilters.map(filter => filter.options).flat(2);
    if (selectedOptions.length === 0) {
      return true;
    }
    return false;
  };

  const setPagePagination = total => {
    let start = null;
    let end = null;
    const of = total;

    // Start number logic
    if (of === 0) {
      start = 0;
      end = 0;
    }

    if (currentpageno === 1 && of > 0) {
      start = 1;
      if (total < rowsPerPage) {
        end = total;
      } else {
        end = rowsPerPage;
      }
    }

    if (currentpageno > 1) {
      start = rowsPerPage * (currentpageno - 1) + 1;
      end = rowsPerPage * currentpageno;
      if (total < end) {
        end = total;
      }
    }

    setPagePaginationText(`Showing ${start}-${end} of ${of}`);
  };

  /** Filter Functionality Ends ------------------ */

  const updateCurrentpageno = (_, newPageNo) => {
    setCurrentpageno(newPageNo);
  };

  const rowsPerPageCallback = updatedRowsPerPage => {
    setCurrentpageno(1);
    setRowsPerPage(updatedRowsPerPage);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (checkForSelectedOptions()) {
      setAllButtonSelected(true);
    }

    const filteredItems = filterHomePageItems(mergedItems, selectedFilters)
      .sort((a, b) => new Date(b.updatedAt_) - new Date(a.updatedAt_));

    // Set new Programs list by setting index start and end
    setAllList(
      filteredItems.slice(
        currentpageno === 1 ? 0 : (currentpageno - 1) * rowsPerPage,
        rowsPerPage * currentpageno
      )
    );

    // Set Total Pages Count
    setTotalPages(Math.ceil(filteredItems.length / rowsPerPage));
    setPagePagination(filteredItems.length);
  }, [selectedFilters, rowsPerPage, currentpageno]);
  return (
    <Layout
      legalFootNote={data.leadDirectorDetails?.footnote?.footnote}
      location={location}
    >
      {/* <Carousel data={data?.leadDirectorDetails?.carousel} /> */}
      {data.leadDirectorDetails.components
        ?.filter(component => component?.__typename)
        .map(component => (
          <section key={component.id}>
            {DynamicComponent(component.__typename, component)}
          </section>
        ))}
      <CardBox>
        <Container sx={{ mb: 10 }}>
          <Box sx={{ mt: 5, mb: 5 }}>
            <Typography align='center' gutterBottom variant='h4' component='div' className='heading'>
              {leadPageData?.pageTitle}
              {' '}
              <br />
              {' '}
            </Typography>
            <Typography align='center' variant='headingdividerline' className='divider' />
            <Typography align='center' gutterBottom variant='body1' component='div' sx={{ mt: 3 }}>
              {documentToReactComponents(JSON.parse(leadPageData?.pageDescription?.raw))}
            </Typography>
          </Box>
          <Box>
            <Grid container spacing={{ xs: 1, sm: 2 }} className='listCards'>
              {allListingDetailsData?.nodes.map(listingDetails => (
                <Grid item xs={12} sm={4}>

                  <Card sx={{ minWidth: 275, p: 5 }} className='cardRef'>
                    <a href={listingUrlMap[listingDetails.type]} className='anchorLink'>
                      <CardContent>
                        <Typography gutterBottom variant='h5' component='div' className='heading'>{listingDetails?.entryTitle}</Typography>
                        <Typography variant='headingdividerline' />
                        <Typography variant='body1' component='div' gutterBottom>
                          {documentToReactComponents(JSON.parse(listingDetails?.pageDescription?.raw))}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <a href={listingUrlMap[listingDetails.type]} className='anchorLink'>
                          <Button startIcon={<PlayCircleOutlineIcon />} className='exploreBtn'>
                            <u>Explore this section</u>
                          </Button>
                        </a>
                      </CardActions>
                    </a>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </CardBox>
      <Container>

        {/* Filter Section */}
        <Grid container spacing={2} sx={{ pt: 4, pl: 1 }}>
          <Grid item xs={12}>
            <Typography variant='h2' component='h2' sx={{ mb: 2 }}> Latest Updates</Typography>
            <Typography variant='headingdividerline' />
          </Grid>
          <Grid item xs={12} sx={{ position: 'relative' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }} justifyContent={{ xs: 'space-between', sm: 'flex-start' }}>
              <Box>
                <ToggleButtonCmp
                  sx={{ display: 'inline-block', mr: 2 }}
                  size='small'
                  selected={allButtonSelected}
                  onChange={() => {
                    setAllButtonSelected(!allButtonSelected);
                    onAllButtonChange();
                  }}
                >
                  All
                </ToggleButtonCmp>
              </Box>
              <Box>
                <ListFilters>
                  {matches ? (
                    <ListFilter filters={getHomePageFilters(mergedItems)} selectedFilters={selectedFilters} onChange={onFilterChange} />
                  ) : (
                    <div>
                      <IconButton
                        size='large'
                        edge='start'
                        color='inherit'
                        aria-label='menu'
                        sx={{ mr: 2 }}
                        aria-describedby={id}
                        variant='contained'
                        onClick={handleClick}
                      >
                        <FilterListIcon />
                      </IconButton>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left'
                        }}

                      >
                        <Grid
                          container
                          direction='column'
                          justifyContent='center'
                          alignItems='center'
                          p={2}
                        >
                          <ListFilter filters={getHomePageFilters(mergedItems)} selectedFilters={selectedFilters} onChange={onFilterChange} />
                        </Grid>
                      </Popover>
                    </div>
                  )}
                </ListFilters>
              </Box>
            </Box>
            <Box sx={{ backgroundColor: '#E95525', height: 5, mt: 2 }} />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ mt: 1 }}>
              <Typography
                variant='cardcategory'
                sx={{ mr: 1 }}
              >
                {pagePaginationText}
              </Typography>
              {
                selectedFilters
                  .map(filter => filter.options
                    .map(option => (
                      <Chip
                        sx={{ mb: 0 }}
                        size='small'
                        key={option}
                        label={option}
                        variant='outlined'
                        onDelete={() => removeFilter(filter.label, option)}
                      />
                    )))
              }
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={1} sx={{ padding: '20px 0 50px' }}>
          {
            allList?.map(item => <ListCard details={item} link={`/${item.link}/${item.slug}`} />)
          }

          {/* Pagination */}
          <Grid container item justifyContent='flex-start' alignItems='center'>
            <Grid xs={12} sm={12} md={4}>
              <PageListPagination totalRows={mergedItems.length} rowsPerPageCallback={rowsPerPageCallback} />
            </Grid>
            <Grid Grid xs={12} sm={12} md={6} lg={4} alignItems='center' justifyContent='center' container>
              <Pagination
                count={totalPages}
                page={currentpageno}
                onChange={updateCurrentpageno}
                variant='outlined'
              />
            </Grid>
            <Grid xs={0} sm={4} />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query LeadDirectorLandingPage {
    leadDirectorDetails: contentfulPageLeadDirector(slug: {eq: "home"}) {
      pageTitle
      pageDescription {
        raw
      }
      entryTitle
      footnote {
        footnote
      }
      components {
        ...compContainers
      }
    }
    listingDetails: allContentfulCompListingDetails(filter: {type: {ne: "base"}}) {
      nodes {
        entryTitle
        type
        contentful_id
        heroImage {
          title
          description
        }
        pageDescription {
          raw
        }
      }
    }
    knowledge: allContentfulPageKnowledge(
      sort: {fields: updatedAt, order: DESC}
      filter: {isDisabled: {ne: true}}
    ) {
      nodes {
        contentful_id
        title
        slug
        category
        updatedAt_: updatedAt # Timestamp
        createdAt(formatString: "MMMM DD, YYYY")
        updatedAt(formatString: "MMMM DD, YYYY")
        description {
          raw
        }
        knowledgeThumbnail {
          file {
            url
          }
          gatsbyImageData(
            aspectRatio: 1.78
            jpegProgressive: true
            formats: WEBP
            resizingBehavior: SCALE
            placeholder: BLURRED
          )
        }
      }
      totalCount
    }
    programs: allContentfulPageProgram(
      sort: {fields: updatedAt, order: DESC}
      filter: {isDisabled: {ne: true}}
    ) {
      nodes {
        contentful_id
        programShortTitle
        slug
        category
        programShortDescription {
          programShortDescription
        }
        programThumbnail {
          file {
            url
          }
          gatsbyImageData(
            aspectRatio: 1.78
            jpegProgressive: true
            formats: WEBP
            resizingBehavior: SCALE
            placeholder: BLURRED
          )
        }
        createdAt(formatString: "MMMM DD, YYYY")
        updatedAt(formatString: "MMMM DD, YYYY")
        updatedAt_: updatedAt # Timestamp
      }
    }
    events: allContentfulPageEvent(
      sort: {fields: updatedAt, order: DESC}
      filter: {isDisabled: {ne: true}}
    ) {
      nodes {
        contentful_id
        title
        slug
        category
        createdAt(formatString: "MMMM DD, YYYY")
        updatedAt(formatString: "MMMM DD, YYYY")
        updatedAt_: updatedAt # Timestamp
        eventShortDescription {
          eventShortDescription
        }
        eventThumbnail {
          file {
            url
          }
          gatsbyImageData(
            aspectRatio: 1.78
            jpegProgressive: true
            formats: WEBP
            resizingBehavior: SCALE
            placeholder: BLURRED
          )
        }
      }
    }
  }

  fragment compContainers on ContentfulCompContainer {
    id
    __typename
    backgroundColor
    backgroundImage {
      file {
        url
      }
    }
    rows {
      id
      title
      columns {
        __typename
        component {
          __typename
          ...carousel
        }
      }
    }
  }

  fragment carousel on ContentfulRanCompCarousel {
    slides {
      button {
        ...buttonLinks
      }
      slideLink {
        ...buttonLinks
      }
      date(formatString: "MMMM DD, YYYY")
      entryTitle
      description {
        description
      }
      image {
        gatsbyImageData(
          aspectRatio: 1.78
          jpegProgressive: true
          formats: WEBP
          resizingBehavior: SCALE
          placeholder: BLURRED
        )
      }
      title
      category
    }
    title
  }

  fragment buttonLinks on ContentfulRanCompLink {
    title
    link {
      ... on ContentfulInternalLink {
        openInANewTab
        entryTitle
        refPage {
          __typename
          ... on ContentfulPageProgram {
            slug
          }
          ... on ContentfulPageUtility {
            slug
          }
          ... on ContentfulPageEvent {
            slug
          }
          ... on ContentfulPageKnowledge {
            slug
          }
        }
      }
      ... on ContentfulCompExternalLink {
        title
        url {
          url
        }
        openInANewTab
        logo {
          file {
            fileName
            url
            contentType
          }
        }
      }
    }
  }
`;
LeadDirectorLandingPage.propTypes = {
  location: PropTypes.shape.isRequired
};

export default LeadDirectorLandingPage;
